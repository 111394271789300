import React, {useState, useEffect, Fragment } from 'react';
import useRealmApp from './useRealmApp';
import { useLocation, useNavigate } from 'react-router';
import qs from 'qs';
import confirmEmail from './confirmEmail';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

const EmailVerificationScreen = () => {
    const {realmApp, realmAppError, loadingRealmApp} = useRealmApp(); //custom hook;
    const location = useLocation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [emailConfirmationError, setEmailConfirmationError] = useState();

    useEffect(()=>{
        if(!loadingRealmApp){
            if(location.search){
                const queryString = qs.parse(location.search,{ ignoreQueryPrefix: true })
                if(queryString.token && queryString.tokenId){
                    console.log("queryString: ", queryString);
                    const token = queryString.token;
                    const tokenId = queryString.tokenId;
                    console.log("token: ", token);
                    console.log("tokenId: ", tokenId);
                    confirmEmail(
                        realmApp,
                        token, 
                        tokenId, 
                        setEmailConfirmationError,
                        setLoading
                    );
                }
            } else {
                navigate("/");
            }
        }
    },[loadingRealmApp, location, navigate, realmApp]);

    if(loading || loadingRealmApp){
        return (
            <LoadingBox/>
        )
    }

    if(realmAppError){
        return (
            <Fragment>
                <MessageBox>{realmAppError}</MessageBox>
                <button 
                    type="button"
                    style={{ "marginTop": "2rem"}}
                    className="primary" 
                    onClick={()=>{
                        navigate("/");
                    }}
                >
                    Go home
                </button>
            </Fragment>
        )
    }

    return (
        emailConfirmationError ? (
            <Fragment>
                {console.log("emailConfirmationError: ", emailConfirmationError)}
                <h1>{emailConfirmationError}</h1>
                <button 
                    type="button"
                    style={{ "marginTop": "2rem"}}
                    className="primary" 
                    onClick={()=>{
                        navigate("/");
                    }}
                >
                    Go home
                </button>
            </Fragment>
        ) : (
            <Fragment>
                <h1>Your email was successfully verified. Click button below to sign in.</h1>
                <button 
                    type="button"
                    style={{ "marginTop": "2rem"}}
                    className="primary" 
                    onClick={()=>{
                        navigate("signin");
                    }}
                >
                    Sign in
                </button>
            </Fragment>
        )
    )

}

export default EmailVerificationScreen;