//see: https://medium.com/tinyso/how-to-create-a-modal-component-in-react-from-basic-to-advanced-a3357a2a716a
//see: https://codeburst.io/a-quick-intro-to-reacts-props-children-cb3d2fce4891
import React from "react";
import './modal.css';

export const ModalTemplate = ({children}) => {
    
    return (
        <div className="modal">
            <div className="modal_content">
                {children}
            </div>
        </div>
    )
}