import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import Product from '../components/Product';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

import { Link } from 'react-router-dom';

//10-18-2022
import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';

// const GET_PRODUCTS = gql`
//   query GetProducts {
//     products {
//       _id
//       image
//       name
//       numReviews
//       price
//       seller {
//         seller {
//           name
//         }
//       }
//     }
//   }
// `

const LIST_PRODUCTS= gql`
  query ListProducts($input: ListProductsInput){
    ListProductsResolver(input: $input){
      productsResult {
        _id
        image
        name
        numReviews
        price
        seller {
          _id
          name
          email
          password
          isAdmin
          isSeller
          seller {
            name
            logo
            description
            numReviews
          }
        }
      }
    }
  }
`

const LIST_TOP_SELLERS = gql`
  query ListTopSellers {
    ListTopSellersResolver {
      topSellersResult{
        _id
        name
        seller {
          name
          logo
        }
      }
    }
  }
`
//

export default function HomeScreen() {

    // const { loading:get_products_loading, error: get_products_error, data:get_products_data } = useQuery(GET_PRODUCTS);
    const { loading:list_products_loading, error: list_products_error, data:list_products_data } = useQuery(LIST_PRODUCTS, {
      variables: {
        "input": {
          "pageNumber": '',
          "seller": '',
          "name": '',
          "category": '',
          "order": '',
          "min": 0,
          "max": 0,
          "rating": 0
        }
      }
    });
    const { loading:list_top_sellers_loading, error: list_top_sellers_error, data:list_top_sellers_data } = useQuery(LIST_TOP_SELLERS);


//   if(list_top_sellers_loading || get_products_loading){
  if(list_top_sellers_loading || list_products_loading){
    return (
      <LoadingBox></LoadingBox>
    )
  }

  if(list_top_sellers_error){
    return (
      <MessageBox variant="danger">{list_top_sellers_error.message}</MessageBox>
    )
  }

//   if(get_products_error){
//     return (
//       <MessageBox variant="danger">{get_products_error.message}</MessageBox>
//     )
//   }

  if(list_products_error){
    return (
      <MessageBox variant="danger">{list_products_error.message}</MessageBox>
    )
  }

  return (
    <div>
      <h2>Top Sellers</h2>
        <>
          {list_top_sellers_data.ListTopSellersResolver.topSellersResult.length === 0 && <MessageBox>No Seller Found</MessageBox>}
          <Carousel showArrows autoPlay showThumbs={false}>
            {list_top_sellers_data.ListTopSellersResolver.topSellersResult.map((seller) => (
              <div key={seller._id}>
                <Link to={`/seller/${seller._id}`}>
                  <img src={seller.seller.logo} alt={seller.seller.name} />
                  <p className="legend">{seller.seller.name}</p>
                </Link>
              </div>
            ))}
          </Carousel>
        </>
      <h2>Featured Products</h2>
        <>
          {/* {get_products_data.products.length === 0 && <MessageBox>No Product Found</MessageBox>}
          <div className="row center">
            {get_products_data.products.map((product) => (
              <Product key={product._id} product={product}></Product>
            ))}
          </div> */}
          {list_products_data.ListProductsResolver.productsResult.length === 0 && <MessageBox>No Product Found</MessageBox>}
          <div className="row center">
            {list_products_data.ListProductsResolver.productsResult.map((product) => (
              <Product key={product._id} product={product}></Product>
            ))}
          </div>
        </>
    </div>
  );
}
