import { useState, useEffect } from 'react';
import { realmAppVar } from '../cache';
import * as Realm from "realm-web";
import { APP_ID } from '../cache';

//Note: Custom hooks always begin with "use";

const useRealmApp = ()=> {
    const [realmApp, setRealmApp] = useState();
    const [realmAppError, setRealmAppError] = useState();
    const [loadingRealmApp, setLoadingRealmApp] = useState(true);

    //see: https://stackoverflow.com/questions/53332321/react-hook-warnings-for-async-function-in-useeffect-useeffect-function-must-ret
    //Note, that changing the name of getRealmApp to useRealmApp strangely resulted in an error; I don't know why
    //Here's the answer: https://www.youtube.com/watch?v=Jl4q2cccwf0

    //Note that using then statements inside useEffect makes for much cleaner code than using a await in a useCallback or an outside function;

    useEffect(()=>{
        if(realmAppVar()){
            realmAppVar().currentUser.refreshCustomData();
            setRealmApp(realmAppVar());
            console.log("realmAppVar() already existed.");
        } else {
            const app = new Realm.App(APP_ID);
            app.logIn(Realm.Credentials.anonymous())
                .then(()=>{
                    console.log("realmAppVar() did not exist but has been set");
                    setRealmApp(realmAppVar(app));
                    // throw new Error("Another error!");
                })
                .catch((err)=>{
                    setRealmAppError(err);
                })
        }
    },[]);

    useEffect(()=>{
        if(realmApp || realmAppError){
            setLoadingRealmApp(false);
        }
    },[realmApp, realmAppError]);

    return {realmApp, realmAppError, loadingRealmApp};

}

export default useRealmApp;