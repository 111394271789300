//We still need to add a top level loading component and error component;
//10-18-2022
//see: https://www.mongodb.com/docs/realm/web/graphql-apollo-react/
//see: https://www.mongodb.com/docs/realm/web/react-web-quickstart/
//see: https://www.mongodb.com/docs/atlas/app-services/manage-apps/create/create-with-ui/#std-label-create-a-realm-app

import * as Realm from "realm-web";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  // InMemoryCache,
} from "@apollo/client";

//
import App from './App';
import { cache, APP_ID } from "./cache";
import { realmAppVar } from "./cache";
//

// Get APP_ID
// imported from environment variable in cache;

// Connect to your MongoDB Realm app
const app = new Realm.App(APP_ID);
// Gets a valid Realm user access token to authenticate requests
async function getValidAccessToken() {
  // Guarantee that there's a logged in user with a valid access token
  try {
    if (!app.currentUser) {
      // If no user is logged in, log in an anonymous user. The logged in user will have a valid
      // access token.
      await app.logIn(Realm.Credentials.anonymous());
      realmAppVar(app);
    } else {
      // An already logged in user's access token might be stale. To guarantee that the token is
      // valid, we refresh the user's custom data which also refreshes their access token.
      await app.currentUser.refreshCustomData();
      realmAppVar(app);
    }
  
    return app.currentUser.accessToken;
  } catch (err){
    console.error(err);
  }
  
}

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const client = new ApolloClient({
  link: new HttpLink({
    uri: `https://realm.mongodb.com/api/client/v2.0/app/${APP_ID}/graphql`,
    // We define a custom fetch handler for the Apollo client that lets us authenticate GraphQL requests.
    // The function intercepts every Apollo HTTP request and adds an Authorization header with a valid
    // access token before sending the request.
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  // cache: new InMemoryCache(),
  cache:cache
});


// ... code to create the GraphQL client
export const AppWithApollo = () => (
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);
//Note: If you are user a component that makes a graphql request you will automatically get an updated app object; otherwise you may have to refresh manually or store the app object in a reactive variable;
//Also see: https://stackoverflow.com/questions/67511644/how-to-get-access-to-client-object-anywhere-in-the-app-with-apollo-client