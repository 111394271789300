//see: https://stackoverflow.com/questions/49886881/validation-using-yup-to-check-string-or-number-length
//https://stackoverflow.com/questions/49394391/conditional-validation-in-yup

//see: https://stackoverflow.com/questions/57391218/use-plugin-international-telephone-input-in-react
//see: https://www.twilio.com/blog/international-phone-number-input-html-javascript
//see: https://www.twilio.com/blog/validate-phone-number-input
//see: https://github.com/xlsdg/react-intl-tel-input-v2
//see: https://stackoverflow.com/questions/54461158/how-to-integrate-react-intl-tel-input-with-formik
//see: https://stackoverflow.com/questions/51364080/access-formiks-values-outside-of-component-react

import React, { useState, useEffect, Fragment, useRef } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

import 'intl-tel-input/build/css/intlTelInput.css';

import TextError from './TextError';

import VerifyWithEmail from './VerifyWithEmail';
// import loginCustomFunction from './loginCustomFunction';

import { useNavigate } from "react-router-dom";
import loginEmailPasswordFunction from './loginEmailPasswordFunction';

const VERIFY_TWILIO_CODE = gql`
    query VerifyTwilioCode($input: VerifyTwilioCodeInput) {
        VerifyTwilioCodeResolver(input: $input) {
            status
        }
    }
`

export default function SendSmsCodeForm({
    setMobileVerification, 
    realmApp, 
    name,
    email, 
    password, 
    setEmailError, 
    setShowModal, 
    setEmailSent, 
    setLoading,
    verificationPhoneNumber
}) {
    const formRef = useRef();
    const [phoneError, setPhoneError] = useState(false);
    const [loginError, setLoginError] = useState();
    const [loadingLogin, setLoadingLogin] = useState(false);
    const navigate = useNavigate();

    //see: https://github.com/jackocnr/intl-tel-input/issues/1220
    //see: https://codepen.io/jackocnr/pen/DyrVbw
    //see: https://www.twilio.com/blog/international-phone-number-input-html-javascript
    //see: https://github.com/jackocnr/intl-tel-input#utilities-script

    const initialValues = {
        'smsCode':''
    };

    //see: https://stackoverflow.com/questions/49394391/conditional-validation-in-yup
    //I don't know why exactly the when statement stopped working.
    const validationSchema = Yup.object({
        "smsCode": Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits")
    });

    const onSubmit = () => {
        loadVerifyTwilioCode({
            variables: {
                "input": {
                    "email":email,
                    "phoneNumber": verificationPhoneNumber,
                    "smsCode": formRef.current.values.smsCode
                }
            }
        }) 
    };


    const [loadVerifyTwilioCode, { loading:loadingVerifyTwilioCode, error }] = useLazyQuery(
        VERIFY_TWILIO_CODE, { 
            onCompleted:async(data)=>{
                console.log("completed!")
                if(data.VerifyTwilioCodeResolver.status === "approved"){
                    setLoadingLogin(true);
                    //You do need to use await for these imported async functions;
                    //You can also use then;
                    try {
                        await loginEmailPasswordFunction(realmApp, name, email, password, setLoginError)
                    } catch(err){
                        console.error(err);
                    }
                    setLoadingLogin(false);
                }
            }
        }
    );
    

    useEffect(()=>{
        if(error){
            setPhoneError(true);
        }
    },[error]);

    if (loadingVerifyTwilioCode || loadingLogin) return <LoadingBox></LoadingBox>;
    if (error && phoneError) {
        return (
            <Fragment>
                <MessageBox variant="danger">
                    {
                        error.message.includes("Authenticate") ? //Means we've run out of Twilio funds;
                        "There is a currently a problem with the site. Please try again later." : 
                        error.message.includes("Invalid parameter `To`:") ? //This probably can't be reached because at this point the phone number has been set but we'll leave it in for now.
                        "The phone number you entered is invalid. Please enter a valid phone number and try again." : 
                        error.message.includes("Invalid parameter: Code") ?
                        "The code you entered is invalid. Please reenter your code and try again." :
                        error.message.includes("The sms code entered may be incorrect.") ?
                        `Error: ${error.message} Please try again.`:
                        `Error: ${error.message} Please try again later.`
                    }
                </MessageBox>
                <button 
                    type="button"
                    style={{ "marginTop": "2rem"}}
                    className="primary" 
                    onClick={()=>{
                        setPhoneError(false);
                    }}
                >
                    Go back
                </button>
            </Fragment>
        );
    }

    if (loginError) {
        return (
             <Fragment>
                <MessageBox variant="danger">
                    {loginError}
                </MessageBox>
                <button 
                    type="button"
                    style={{ "marginTop": "2rem"}}
                    className="primary" 
                    onClick={()=>{
                        //see Austin Greco's answer here: https://stackoverflow.com/questions/46820682/how-do-i-reload-a-page-with-react-router
                        navigate(0);
                    }}
                >
                    Go back
                </button>
            </Fragment>
        )
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            <Form 
                className="form" 
            >
                <div>
                    <h1>"Verify Code"</h1>
                </div>
                    <div>
                        <label htmlFor="smsCode">Enter code</label>
                        <Field
                            type="text"
                            id="smsCode"
                            name="smsCode"
                            placeholder="Enter code"
                        ></Field>
                        <ErrorMessage name='smsCode' component={TextError}/>
                    </div>
                <div>
                    <label />
                    <button 
                        className="primary" 
                        type="submit"
                    >
                        "Send Verification Code" 
                    </button>
                </div>
                <div>
                    <label />
                    <button 
                        type="button"
                        className="primary" 
                        onClick={()=>{
                            setMobileVerification(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
                <VerifyWithEmail
                    setMobileVerification={setMobileVerification}
                    realmApp={realmApp}
                    email={email}
                    password={password}
                    setEmailError={setEmailError}
                    setShowModal={setShowModal}
                    setEmailSent={setEmailSent}
                    setLoading={setLoading}
                />
            </Form>
        </Formik>
    );
}