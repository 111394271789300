//see: https://medium.com/tinyso/how-to-create-a-modal-component-in-react-from-basic-to-advanced-a3357a2a716a
//see: https://www.mongodb.com/docs/realm/web/graphql-apollo-react/
//see https://www.apollographql.com/docs/react/api/react/hooks/#uselazyquery
//open the link in Chrome; some parts are missing in FireFox;

import React from "react";
import './modal.css';

import { ModalTemplate } from "./ModalTemplate";
import { registerUserWithEmail } from "../screens/registerUserWithEmail";
import GoogleOneTapLoginButton from "../screens/GoogleOneTapLoginButton";

//see: https://www.mongodb.com/docs/realm/web/authenticate/#std-label-web-google-onetap                
//see: https://www.youtube.com/watch?v=rXNEqnAE2Bk
//see: https://www.youtube.com/watch?v=e15zMhr-DFE
//see: https://www.npmjs.com/package/google-one-tap
//see: https://www.youtube.com/watch?v=IPyl0igVkH4

export const RegisterMethodModal = ({
    setMobileVerification, 
    setShowModal, 
    realmApp, 
    name,
    email, 
    password, 
    setEmailError, 
    setEmailSent, 
    setLoading
}) => {

    return (
        <ModalTemplate>
            <div>Would you like to register with your mobile device or with your email?</div>
            <button
                className='modal_button'
                onClick={()=>{
                    setMobileVerification(true);
                    setShowModal(false);
                }}
            >
                Register with mobile device
            </button>
            <button
                className='modal_button'
                onClick={async () => {
                    setLoading(true);
                    await registerUserWithEmail({
                        realmApp, 
                        name,
                        email, 
                        password, 
                        setEmailError, 
                        setShowModal, 
                        setEmailSent
                    });
                    setLoading(false);
                }}
            >
                Register with email
            </button>
            {/* <button
                className='modal_button'
                onClick={async () => {
                    setLoading(true);
                   

                    setLoading(false);
                }}
            >
                Register with Google
            </button> */}
            <GoogleOneTapLoginButton/>
            <button
                className='modal_button'
                onClick={()=>{
                    setShowModal(false);
                }}
            >
                Cancel
            </button>
        </ModalTemplate>
    )
}