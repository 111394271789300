//see: https://dev.to/kwirke/implementing-a-shopping-cart-with-apollojs-3-p47
import { InMemoryCache } from "@apollo/client";
import { makeVar } from "@apollo/client";

//see: https://stackoverflow.com/questions/50122764/using-environment-variables-in-react-application
//see: https://stackoverflow.com/questions/42182577/is-it-possible-to-use-dotenv-in-a-react-project
//Note the there are separate .env files for the back and front ends.

//environment variables must begin with "REACT_APP";
//see: https://create-react-app.dev/docs/adding-custom-environment-variables/
//You must restart the server (on the frontend in this case) to update the .env file;

export const APP_ID = process.env.REACT_APP_ID;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const cartItemsVar = makeVar([]);
export const realmAppVar = makeVar();

export const cache = new InMemoryCache({});