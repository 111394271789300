export const registerUserWithEmail = async ({
    realmApp, 
    name,
    email, 
    password, 
    setEmailError, 
    setShowModal, 
    setEmailSent
})=>{
    try {
        const lowerCaseEmail = email.toLowerCase();
        //insert function here to insert arbitrary info?
        const response = await realmApp.currentUser.functions.AddTempUserData({name, email, bypass:false});
        //const response = await realmApp.currentUser.callFunction("AddTempUserData", [{name, email}]);
        console.log(response);
        await realmApp.emailPasswordAuth.registerUser({email:lowerCaseEmail, password});
        setEmailSent(true);
    } catch (err){
        setEmailError(err);
    }

    setShowModal(false);
}