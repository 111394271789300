import * as Realm from "realm-web";
// import extractErrorMessage from "./extractErrorMessage";

//see: https://www.mongodb.com/docs/realm/web/authenticate/
export default async function loginEmailPasswordFunction(realmApp, name, email, password, setLoginError) {
    try {
      const response = await realmApp.currentUser.functions.BypassEmailConfirmation({name, email, password});
      //const response = await realmApp.currentUser.callFunction("BypassEmailConfirmation", [{name, email, password}]);
      console.log(response);

      const credentials = Realm.Credentials.emailPassword(email, password); //the credentials determines the provider type

      // Authenticate the user
      //see: https://www.mongodb.com/community/forums/t/how-remove-delete-user-account-for-websdk/102023/3
      const allUsersArray = Object.values(realmApp.allUsers);
      allUsersArray.forEach(async(user) => {
        await realmApp.removeUser(user);
      })
      const user = await realmApp.logIn(credentials);
      // `App.currentUser` updates to match the logged in user
      console.log(realmApp.allUsers);
      console.log("Object.values(realmApp.allUsers).length: ", Object.values(realmApp.allUsers).length);
      console.assert(user.id === realmApp.currentUser.id);
      await user.refreshCustomData();
      console.log(user);
      return user;
    } catch (err) {
      console.error(err);
      if(err.message.includes("409 Conflict")){
        setLoginError("409 Conflict. A user with this email may already exist. Choose a different email and resubmit.")
      } else {
        setLoginError(err.message);
      }
    }
  }
  