//see: https://www.youtube.com/watch?v=vJtyp1YmOpc;
//see: https://stackoverflow.com/questions/56742376/react-formik-trigger-validation-only-on-form-submit
//see: https://stackoverflow.com/questions/61862252/yup-schema-validation-password-and-confirmpassword-doesnt-work

import React, { useState, useRef, Fragment, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

import { Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';

import { RegisterMethodModal } from '../modals/RegisterMethodModal';
import PhoneVerification from './PhoneVerification';

import TextError from './TextError';
import useRealmApp  from './useRealmApp';
import capitalizeFirstLetter from './capitalizeFirstLetter';
// import googleOneTap from 'google-one-tap';

// console.log("process.env.REACT_APP_GOOGLE_CLIENT_ID: ", process.env.REACT_APP_GOOGLE_CLIENT_ID);
// const options = {
//   // client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // required
//   client_id: "696401677681-i6sgfho095l1f3157700h727vgfdt94m.apps.googleusercontent.com",
//   auto_select: false, // optional
//   cancel_on_tap_outside: false, // optional
//   context: 'signin' // optional
// };

export default function RegisterScreen() {
  const [mobileVerification, setMobileVerification] = useState(false);
  const {realmApp, realmAppError, loadingRealmApp} = useRealmApp(); //custom hook;

  //see: https://stackoverflow.com/questions/51364080/access-formiks-values-outside-of-component-react
  const formRef = useRef();

  const initialValues = {
    'name': 'dj',
    'email':'cyrusj12@gmail.com',
    'password':'123456',
    'confirmPassword':'123456'
  }

  const validationSchema = Yup.object({
    "name": Yup.string()
      .max(15, "Must be 15 characters or less")
      .required("Name is required"),
    "email": Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    "password": Yup.string()
      .min(6, "Must be a minimum of 6 characters")
      .max(128, "Must be 128 characters or less")
      .required('Password is required'),
    "confirmPassword": Yup.string()
      .test('passwords-match', 'Passwords must match', function(value){ //Vishwas had a different method of handling this although ours might be better;
        return this.parent.password === value
      })
  })

  const onSubmit = () => {
      setShowModal(true);
  };

  const [showModal, setShowModal] = useState(false);
  const [emailError, setEmailError] = useState();
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [oneTap, setOneTap] = useState();
  useEffect(()=>{
    setLoading(false);
  },[])

  // useEffect(()=>{
  //   setOneTap(googleOneTap(options, (response) => {
  //         // Send response to server
  //         console.log(response);
  //         console.log("anything?")
  //   }))
  // },[]);

  // useEffect(()=>{
  //   console.log("oneTap: ", oneTap);
  // }, [oneTap])


  if (emailError) {
    return (
      <Fragment>
        <MessageBox variant="danger">{
            `Error: ${
              emailError.error === "email invalid" ? 
              capitalizeFirstLetter(emailError.error) + ". Please use a valid email address and try again." : 
              emailError.error === "name already in use" ? 
              capitalizeFirstLetter(emailError.error) + ". Please choose a different email address and try again." : 
              emailError.error
            }`
          }
        </MessageBox>
        <button 
            type="button"
            style={{ "marginTop": "2rem"}}
            className="primary" 
            onClick={()=>{
                setEmailError(null);
            }}
        >
            Go back
        </button>
      </Fragment>
    );
  }

  if (emailSent) {
    return (
      <h1>An email was sent to the provided email address. Please check your inbox to confirm your email address.</h1>
    )
  }

  return (
    loading || loadingRealmApp ?
      <LoadingBox/> :
    realmAppError ?
      <MessageBox>{realmAppError.message}</MessageBox> :
    realmApp ?
      <div>
        {mobileVerification ? (
          <PhoneVerification
            setMobileVerification={setMobileVerification}
            realmApp={realmApp}
            name={formRef.current?.values.name}
            email={formRef.current?.values.email}
            password={formRef.current?.values.password}
            setEmailError={setEmailError}
            setShowModal={setShowModal}
            setEmailSent={setEmailSent}
            setLoading={setLoading}
          />
        ) : (
          <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
          >
            <Form 
              noValidate
              className="form" 
            >
              <div>
                <h1>Create Account</h1>
              </div>
              <div>
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter name"
                ></Field>
                <ErrorMessage name='name' component={TextError}/>
              </div>
              <div>
                <label htmlFor="email">Email address</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Enter email"
                ></Field>
                <ErrorMessage name='email' component={TextError}/>
              </div>
              <div>
                <label htmlFor="password">Password</label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter password"
                ></Field>
                <ErrorMessage name='password' component={TextError}/>
              </div>
              <div>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Enter confirm password"
                ></Field>
                <ErrorMessage name='confirmPassword' component={TextError}/>
              </div>
              <div>
                <label />
                <button className="primary" type="submit">
                  Register
                </button>
              </div>
              <div>
                <label />
                <div>
                  Already have an account?{' '}
                  <Link to={`/signin`}>Sign-In</Link>
                </div>
              </div>
            </Form>
          </Formik>
        )}
        {showModal && 
          <RegisterMethodModal
            setShowModal={setShowModal}
            setMobileVerification={setMobileVerification}
            name={formRef.current?.values.name}
            email={formRef.current?.values.email}
            password={formRef.current?.values.password}
            setEmailError={setEmailError}
            realmApp={realmApp}
            setEmailSent={setEmailSent}
            setLoading={setLoading}
          />
        }
      </div>
      :
      <Navigate to="/" replace={true} />
  );
}
