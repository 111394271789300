import * as Realm from "realm-web";

const confirmEmail = async (
    realmApp, 
    token, 
    tokenId,
    setEmailConfirmationError,
    setLoading
) => {

    try {
        //see: https://www.mongodb.com/docs/realm/web/authenticate/#std-label-web-login-email-password
        //see: https://www.mongodb.com/docs/realm-sdks/js/latest/Realm.Auth.EmailPasswordAuth.html
        await realmApp.emailPasswordAuth.confirmUser({token, tokenId});
        //Note: We are just logging in to test code; in reality we wouldn't have access to the email and the password;
        const credentials = Realm.Credentials.emailPassword("cyrusj12@gmail.com", "123456");
        const user = await realmApp.logIn(credentials);
        await user.refreshCustomData();
        console.log("email/password user: ", user);
    } catch(err){
        console.error(err);
        // const errorMessage = err.message.split(":")[2].trim();
        if(err.message.includes("userpass token is expired or invalid (status 404)")){
            setEmailConfirmationError("Your userpass token is expired or invalid. You may have already confirmed your email. Please login at the sign in page or reattempt to register.")
        } else {
            setEmailConfirmationError("Something went wrong verifying your email. Please try again later.")
        }
    }
    setLoading(false);
}

export default confirmEmail;