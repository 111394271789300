//see: https://stackoverflow.com/questions/49886881/validation-using-yup-to-check-string-or-number-length
//https://stackoverflow.com/questions/49394391/conditional-validation-in-yup

//see: https://stackoverflow.com/questions/57391218/use-plugin-international-telephone-input-in-react
//see: https://www.twilio.com/blog/international-phone-number-input-html-javascript
//see: https://www.twilio.com/blog/validate-phone-number-input
//see: https://github.com/xlsdg/react-intl-tel-input-v2
//see: https://stackoverflow.com/questions/54461158/how-to-integrate-react-intl-tel-input-with-formik
//see: https://stackoverflow.com/questions/51364080/access-formiks-values-outside-of-component-react

import React, { useState, useEffect, Fragment, useRef } from 'react';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useLazyQuery } from '@apollo/client';
import gql from 'graphql-tag';

import LoadingBox from '../components/LoadingBox';
import MessageBox from '../components/MessageBox';

import ReactIntlTelInput from 'react-intl-tel-input-v2';

import 'intl-tel-input/build/css/intlTelInput.css';

import TextError from './TextError';
import VerifyWithEmail from './VerifyWithEmail';

const GET_TWILIO_CODE = gql`
    query GetTwilioCode($input: GetTwilioCodeInput) {
        GetTwilioCodeResolver(input: $input) {
            status
        }
    }
`

export default function GetSmsCodForm({
    setMobileVerification, 
    realmApp, 
    name,
    email, 
    password, 
    setEmailError, 
    setShowModal, 
    setEmailSent, 
    setLoading,
    setSmsCodeReceived,
    verificationPhoneNumber,
    setVerificationPhoneNumber
}) {
    const formRef = useRef();
    const [phoneError, setPhoneError] = useState(false);
    //see: https://github.com/jackocnr/intl-tel-input/issues/1220
    //see: https://codepen.io/jackocnr/pen/DyrVbw
    //see: https://www.twilio.com/blog/international-phone-number-input-html-javascript
    //see: https://github.com/jackocnr/intl-tel-input#utilities-script
    
    const intlTelOpts = {
        preferredCountries: ["us", "ca", "gb", "mx"], //google iso2 code to get abbreviations;
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
        // utilsScript: "https://bassir-with-updates.s3.us-west-2.amazonaws.com/amazona-master/frontend/src/screens/intl-tel-input-utils.js?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAMaCXVzLXdlc3QtMSJGMEQCIGtFJ8UlraVGU9TA5%2FlGsY%2BMfMOj5wYIGknoH80V5ZJOAiBarYIHOVRtnvwQok9NPSysbxxM4AViGm6R%2Fpz9PcpxgSrtAgj8%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDg4NDE3NTY2NDY0NyIMoPsrfnv43mwaYqoSKsECjXHdaNwHnK%2BFDSd1otXQABesSRPd6cNymmKMo4z2KHZukTDVmNxpt02ylszrw%2FYfszVcDgmPb%2F0ipNks%2BQzHcrXRLlEJEA4xh93EJoUNMpFW4XQ%2Bz7nLLW0NVybtQQ%2F8%2FwTRtQZV3%2BZBpWMeueOYz1UbqpGzI3ohNYi%2BqyfVryC7QP2V4mwW%2Be5K4%2FzjM5TLEXeiacmYOM63tSle3tSdF%2FIIyidFneVFfoaF8UyudJ2KMY7gpTQXwpWDlkmCBhYr5iBBmV1zLnC5kbLwkCTmlkYRmWjEfhTXJ3RZn%2FRbOZDlgqM%2BNcS3EjdVZM9jYZHu60Dj8QRBRulCSVSfoy1OtNbM69oiQ9sufPddICQciTxRgoFoKTwJL4%2FoAiG457NjHMdmXaJbpZGmS3IvwCwzuuJCVPF9PkUcZhuOPPl8bX4dMOKr0ZsGOrQCqWg9O3bfUpMOM6fO5qu%2BRgx9%2F32yQhxPchVCMTuJNhODk1TkivRaj6V8kuDvTks2B16SxnobAW0XFh6qqtTbttDqFyAusMAh%2BS12jQBKjK7LchH43D%2BKlSnGIo%2Bk4zx0DJu386zkkYnlnawuflLQdkkWECGCEjlq9Kbpdw0W0Y%2B9EZRoDbkvFiZFFXekhvbnxQ9loGOWkCtsEVDmPPp%2BfNSMCUgCSx0pOevZxjomzrh8GIAxnMWhyn6Go9yaXxyE%2Bhwm6CclQAW%2BrlwbTjD5z0GkNLcIiSuKYKLafCgPdVjbxPJnWqMXwUMa%2F6USeb%2F9ojFzjgX%2Bji2t2WnLa2ptAb5Ge0NbASPh8EhL%2F7%2FpyjtCG%2BC90MyYyHcAE1i2mmx58%2FE8X1Ie%2B2euDrN6XmHqIP4xR%2F8%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221116T031845Z&X-Amz-SignedHeaders=host&X-Amz-Expires=299&X-Amz-Credential=ASIA43XHZ6YDTC72XXGA%2F20221116%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=26fab9cd04663d29bac6a020cd86ff86f9185c9a5a7a3c6e678977752a2c85d0"
        // utilsScript: "https://bassir-with-updates.s3.us-west-2.amazonaws.com/amazona-master/frontend/src/screens/intl-tel-input-repo-utils.js?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAMaCXVzLXdlc3QtMSJGMEQCIGtFJ8UlraVGU9TA5%2FlGsY%2BMfMOj5wYIGknoH80V5ZJOAiBarYIHOVRtnvwQok9NPSysbxxM4AViGm6R%2Fpz9PcpxgSrtAgj8%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAIaDDg4NDE3NTY2NDY0NyIMoPsrfnv43mwaYqoSKsECjXHdaNwHnK%2BFDSd1otXQABesSRPd6cNymmKMo4z2KHZukTDVmNxpt02ylszrw%2FYfszVcDgmPb%2F0ipNks%2BQzHcrXRLlEJEA4xh93EJoUNMpFW4XQ%2Bz7nLLW0NVybtQQ%2F8%2FwTRtQZV3%2BZBpWMeueOYz1UbqpGzI3ohNYi%2BqyfVryC7QP2V4mwW%2Be5K4%2FzjM5TLEXeiacmYOM63tSle3tSdF%2FIIyidFneVFfoaF8UyudJ2KMY7gpTQXwpWDlkmCBhYr5iBBmV1zLnC5kbLwkCTmlkYRmWjEfhTXJ3RZn%2FRbOZDlgqM%2BNcS3EjdVZM9jYZHu60Dj8QRBRulCSVSfoy1OtNbM69oiQ9sufPddICQciTxRgoFoKTwJL4%2FoAiG457NjHMdmXaJbpZGmS3IvwCwzuuJCVPF9PkUcZhuOPPl8bX4dMOKr0ZsGOrQCqWg9O3bfUpMOM6fO5qu%2BRgx9%2F32yQhxPchVCMTuJNhODk1TkivRaj6V8kuDvTks2B16SxnobAW0XFh6qqtTbttDqFyAusMAh%2BS12jQBKjK7LchH43D%2BKlSnGIo%2Bk4zx0DJu386zkkYnlnawuflLQdkkWECGCEjlq9Kbpdw0W0Y%2B9EZRoDbkvFiZFFXekhvbnxQ9loGOWkCtsEVDmPPp%2BfNSMCUgCSx0pOevZxjomzrh8GIAxnMWhyn6Go9yaXxyE%2Bhwm6CclQAW%2BrlwbTjD5z0GkNLcIiSuKYKLafCgPdVjbxPJnWqMXwUMa%2F6USeb%2F9ojFzjgX%2Bji2t2WnLa2ptAb5Ge0NbASPh8EhL%2F7%2FpyjtCG%2BC90MyYyHcAE1i2mmx58%2FE8X1Ie%2B2euDrN6XmHqIP4xR%2F8%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20221116T032700Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIA43XHZ6YDTC72XXGA%2F20221116%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=c73f70121b51fb8bed44e954d1afd023624203399fc2ed67648966b57fae9dd1"
    };

    const initialValues = {
        'phoneNumber':{
            'iso2': 'us', 
            'dialCode': '1', 
            'phone': '2532284415' 
        },
    };

    //see: https://stackoverflow.com/questions/49394391/conditional-validation-in-yup
    //I don't know why exactly the when statement stopped working.
    const validationSchema = Yup.object({
        "phoneNumber": Yup.object({
            'iso2': Yup.string(), 
            'dialCode': Yup.string(), 
            'phone': Yup.string().required("You must enter a phone number.")
        })
    });

    const onSubmit = () => {
        loadGetTwilioCode({
            variables: {
                "input": {
                    "phoneNumber":verificationPhoneNumber,
                    "email":email
                }
            }
        }) 
    };

    const [loadGetTwilioCode, { loading, error }] = useLazyQuery(
        GET_TWILIO_CODE, { 
            onCompleted:(data)=>{
                if(data.GetTwilioCodeResolver.status === "pending"){
                    setSmsCodeReceived(true);
                } 
            }
        }
    );

    useEffect(()=>{
        if(error){
            setPhoneError(true);
        }
    },[error]);

    if (loading) return <LoadingBox></LoadingBox>;
    if (error && phoneError) {
        return (
            <Fragment>
                <MessageBox variant="danger">{
                    error.message.includes("Authenticate") ? //Means we've run out of Twilio funds;
                    "There is a currently a problem with the site. Please try again later." : 
                    error.message.includes("Invalid parameter `To`:") ? 
                    `Error: ${error.message}. The phone number you entered is invalid. Please enter a valid phone number and try again.`:
                    error.message.includes("A user with this email already exists. Choose a different email and resubmit") ? 
                    `Error: ${error.message}.` :
                    `Error: ${error.message}`}</MessageBox>
                <button 
                    type="button"
                    style={{ "marginTop": "2rem"}}
                    className="primary" 
                    onClick={()=>{
                        setPhoneError(false);
                    }}
                >
                    Go back
                </button>
            </Fragment>
        );
    }

    return (
        <Formik
            innerRef={formRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            <Form 
                className="form" 
            >
                <div>
                    <h1>Get verification code</h1>
                </div>
                    <div>
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <Field name='phoneNumber'>
                            {({ field: { value }, form: { isSubmitting, setFieldValue}}) => {
                                    return (
                                        <div>
                                            <ReactIntlTelInput
                                                value={value}
                                                onChange={(value) => {
                                                    setFieldValue('phoneNumber', value);
                                                    setVerificationPhoneNumber(`${value.dialCode}${value.phone}`);

                                                }}
                                                intlTelOpts={intlTelOpts}
                                                inputProps={{
                                                    disabled:isSubmitting
                                                }}
                                            />
                                            <ErrorMessage name='phoneNumber.phone' component={TextError}/>
                                        </div>
                                    )
                                }
                            }
                        </Field>
                    </div>
                <div>
                    <label />
                    <button 
                        className="primary" 
                        type="submit"
                    >
                        Get verification code
                    </button>
                </div>
                <div>
                    <label />
                    <button 
                        type="button"
                        className="primary" 
                        onClick={()=>{
                            setMobileVerification(false);
                        }}
                    >
                        Cancel
                    </button>
                </div>
                <VerifyWithEmail
                    setMobileVerification={setMobileVerification}
                    realmApp={realmApp}
                    name={name}
                    email={email}
                    password={password}
                    setEmailError={setEmailError}
                    setShowModal={setShowModal}
                    setEmailSent={setEmailSent}
                    setLoading={setLoading}
                />
            </Form>
        </Formik>
    );
}