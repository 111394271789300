import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

//10-18-2022: 
//We will remove strict mode tags because they interfere with React PayPal Button. We can do this later though.

//import AppWithApollo; see: https://www.mongodb.com/docs/realm/web/graphql-apollo-react/
import {AppWithApollo} from './AppWithApollo';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      {/* <App /> */}
      <AppWithApollo/>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
