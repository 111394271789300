//see: https://www.youtube.com/watch?v=sJwAkjScJtw
//see: https://raw.githubusercontent.com/codelikepro22/place-to-stay/part3/client/src/components/user/GoogleOneTapLogin.js
//Don't forget to give credit where credit is due;
import React, { useState } from 'react';
import '../modals/modal.css';

const GoogleOneTapLoginButton = () => {
  const [disabled, setDisabled] = useState(false);

  const handleResponse = (response) => {
    const token = response.credential;
    console.log("google token: ", token);
  };

  const handleGoogleLogin = () => {
    setDisabled(true);
    try {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleResponse,
      });
      window.google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          throw new Error('Try to clear the cookies or try again later!');
        }
        if (
          notification.isSkippedMoment() ||
          notification.isDismissedMoment()
        ) {
          setDisabled(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <button
        className='modal_button'
        variant="outlined"
        disabled={disabled}
        onClick={handleGoogleLogin}
    >
        Login with Google
    </button>
  );
};

export default GoogleOneTapLoginButton;