import React, {useState, Fragment} from "react";
import SendSmsCodeForm from "./SendSmsCodeForm";
import GetSmsCodeForm from "./GetSmsCodeForm";

export default function PhoneVerification({
    setMobileVerification, 
    realmApp, 
    name,
    email, 
    password, 
    setEmailError, 
    setShowModal, 
    setEmailSent, 
    setLoading
}) {
    const [verificationPhoneNumber, setVerificationPhoneNumber] = useState();
    const [smsCodeReceived, setSmsCodeReceived] = useState(false);

    return (
        <Fragment>
            {smsCodeReceived ? (
                <SendSmsCodeForm
                    setMobileVerification={setMobileVerification}
                    realmApp={realmApp}
                    name={name}
                    email={email}
                    password={password}
                    setEmailError={setEmailError}
                    setShowModal={setShowModal}
                    setEmailSent={setEmailSent}
                    setLoading={setLoading}
                    verificationPhoneNumber={verificationPhoneNumber}
                />
            ):(
                <GetSmsCodeForm
                    setMobileVerification={setMobileVerification}
                    realmApp={realmApp}
                    name={name}
                    email={email}
                    password={password}
                    setEmailError={setEmailError} 
                    setShowModal={setShowModal}
                    setEmailSent={setEmailSent}
                    setLoading={setLoading}
                    setSmsCodeReceived={setSmsCodeReceived}
                    verificationPhoneNumber={verificationPhoneNumber}
                    setVerificationPhoneNumber={setVerificationPhoneNumber}
                />
            )}
        </Fragment>
    );
}