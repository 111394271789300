import React from 'react';
import { registerUserWithEmail } from './registerUserWithEmail';

export default function VerifyWithEmail({
    setMobileVerification, 
    realmApp, 
    name,
    email, 
    password, 
    setEmailError, 
    setShowModal, 
    setEmailSent, 
    setLoading,
}) {
    return (
        <div>
            <label />
            <div>
                Verify with email instead?{' '}
                <button 
                    type="button"
                    className="link-button"
                    onClick={async () => {
                        setLoading(true);
                        await registerUserWithEmail({
                            realmApp, 
                            name,
                            email, 
                            password, 
                            setEmailError, 
                            setShowModal, 
                            setEmailSent
                        });
                        setLoading(false);
                        setMobileVerification(false);
                    }}
                >
                    Verify with email
                </button>
            </div>
        </div>
    );
}